<template>
    <v-container
        fluid
        class="ma-0 pa-0">
        <v-card
            tile
            class="footer secondary--text font-weight-bold"
            dark
            elevation="0"
            height="100%"
            min-height="400px"
            :class="$vuetify.breakpoint.mdAndUp ? 'px-15 py-5' : 'pa-5'">
            <div class="footer-container">
                <div class="mb-10 mt-5">
                    <v-btn
                        icon
                        color="secondary"
                        href="https://www.facebook.com/r2rii"
                        target="_blank"
                        ><v-icon>mdi-facebook</v-icon></v-btn
                    >
                    <!--<v-btn icon href="https://www.instagram.com/cetamadamadag/" target="_blank"><v-icon>mdi-instagram</v-icon></v-btn>-->
                    <v-btn
                        icon
                        color="secondary"
                        href="https://www.linkedin.com/in/ruddy-riina-a82134165/"
                        target="_blank"
                        ><v-icon>mdi-linkedin</v-icon></v-btn
                    >
                    <!--<v-btn icon href="https://www.youtube.com/user/AssociationCetamada" target="_blank"><v-icon>mdi-youtube</v-icon></v-btn>-->
                </div>
                <div
                    class="mb-10 text-h5"
                    style="font-family: 'Arial', sans-serif !important">
                    <v-row>
                        <v-col>Address</v-col>
                        <v-col v-if="!$vuetify.breakpoint.smAndDown"></v-col>
                        <v-col><div :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-end'">Contact</div> </v-col>
                    </v-row>
                </div>

                <v-row>
                    <v-col
                        cols="6"
                        md="4">
                        <v-row :style="!$vuetify.breakpoint.mdAndUp ? 'align-items: flex-start !important; align-content: flex-start !important;' : 'align-items: center'">
                            <v-col
                                :style="!$vuetify.breakpoint.mdAndUp ? 'max-height: 48px' : ''"
                                cols="12"
                                md="2"
                                style="display: flex; flex-direction: column; justify-content: center; align-items: flex-start; align-content: flex-start">
                                <v-icon color="secondary">mdi-map-marker</v-icon>
                            </v-col>
                            <v-col
                                cols="12"
                                md="10"
                                style="display: flex; flex-direction: column; justify-content: center">
                                <div style="width: fit-content">
                                    <span>
                                        Antananarivo, Madagascar <br />Immeuble Jacaranda<br />
                                        Bureau N-1<br />
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="6"
                        md="4"
                        style="display: flex; flex-direction: column; align-content: center; align-items: center">
                        <v-row :style="!$vuetify.breakpoint.mdAndUp ? 'align-items: flex-start !important; align-content: flex-start !important;' : 'align-items: center'">
                            <v-col
                                :style="!$vuetify.breakpoint.mdAndUp ? 'max-height: 48px' : ''"
                                cols="12"
                                md="2"
                                style="display: flex; flex-direction: column; justify-content: center; align-items: flex-start; align-content: flex-start">
                                <v-icon color="secondary">mdi-phone</v-icon>
                            </v-col>
                            <v-col
                                cols="12"
                                md="10"
                                :style="!$vuetify.breakpoint.mdAndUp ? 'justify-content: flex-start' : 'justify-content: center'"
                                style="display: flex; flex-direction: column">
                                <div style="width: fit-content">
                                    <a
                                        href="tel:+261380859555"
                                        class="text-decoration-none secondary--text">
                                        +261 38 08 595 55
                                    </a>
                                    <!--  <br />
                                    <a
                                        href="tel:+261345548700"
                                        class="text-decoration-none secondary--text">
                                        +261 34 55 487 00
                                    </a> -->
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        class="text-end"
                        cols="12"
                        md="4"
                        style="display: flex; flex-direction: row; align-content: center; align-items: center; justify-content: flex-end">
                        <v-icon
                            color="secondary"
                            class="mr-5"
                            >mdi-email</v-icon
                        ><a
                            href="mailto:ruddyriina@gmail.com"
                            class="secondary--text"
                            >ruddyriina@gmail.com</a
                        >
                    </v-col>
                </v-row>
                <div class="text-center my-10 pt-10"></div>
                <v-row
                    class="text-end mb-5"
                    style="justify-content: center; display: flex; flex-direction: row; align-content: center; align-items: center">
                    <!--  <v-img
                        id="logo"
                        src="/logow.png"
                        height="30"
                        contain
                        class="logo-fixe"
                        alt="Meridius AI Solutions"></v-img> -->
                    <v-spacer></v-spacer>
                    <!--  <v-btn
                        text
                        @click="credit()"
                        style="text-transform: none"
                        >Credits and Disclaimer</v-btn
                    > -->
                    <span class="ml-3"> © 2023 All Rights Reserved </span>
                </v-row>
            </div>
        </v-card>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';

export default {
    name: 'Footer',
    methods: {
        credit() {
            this.$emit('credit');
        },
    },
    data() {
        return {};
    },
};
</script>
<style scoped>
.footer {
    background-color: #00000000;
}
.footer-container {
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin: auto;
}
.logo-fixe {
    max-width: 168px;
}
</style>
