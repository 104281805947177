<template>
    <v-app>
        <v-app-bar
            app
            color="transparent"
            class="gradient-app-bar"
            elevation="0"
            dark>
            <div class="d-flex align-center">
                <v-btn
                    href="/#avatar"
                    icon
                    class="mr-2"
                    color="secondary"
                    width="64"
                    height="64">
                    <v-icon
                        alt="Avatar"
                        size="64"
                        >mdi-account-circle</v-icon
                    >
                </v-btn>

                <div class="hidden-sm-and-down secondary--text font-weight-light text-h6">Portfolio</div>
            </div>

            <v-spacer></v-spacer>

            <v-btn
                href="/files/ruddy_riina_cv.pdf"
                target="_blank"
                color="secondary"
                text>
                <span class="mr-2">CV</span>
                <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn
                href="/#footer"
                color="secondary"
                text>
                <span class="mr-2">Contact</span>
                <v-icon>mdi-phone</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main class="main-container">
            <div id="particles-js"></div>
            <v-fade-transition>
                <div
                    v-if="loading"
                    class="loader">
                    <transition
                        name="loader-fade"
                        enter-class="loader-fade-enter-from"
                        leave-to-class="loader-fade-leave-to">
                        <div
                            class="loaderi"
                            v-if="showloader"
                            v-html="loader"></div>
                    </transition>
                    <v-fade-transition>
                        <div
                            v-if="!endloading"
                            class="loadingio-spinner-ripple-64cwf99hv1r">
                            <div class="ldio-ossuqae55ee">
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </v-fade-transition>
                </div>
            </v-fade-transition>
            <router-view @loaded="start" />
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'App',
    mounted() {
        document.documentElement.style.scrollBehavior = 'smooth';
        tsParticles.loadJSON('particles-js', 'particles.json').then(() => {
            console.log('callback - particles.js config loaded');
        });
    },
    methods: {
        start() {
            setTimeout(() => {
                this.showloader = false;
                setTimeout(() => {
                    this.loader = this.svg;
                    this.endloading = true;
                    this.showloader = true;
                    setTimeout(() => {
                        this.loading = false;
                    }, 2000);
                }, 500);
            }, 500);
        },
    },

    data: () => ({
        loading: true,
        endloading: false,
        showloader: true,
        loader: 'Loading',
        svg: ` <div style="justify-self: start; align-self: center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="77.654" height="77.654" viewBox="0 0 77.654 77.654" class="mt-1">
                            <g id="Groupe_2" data-name="Groupe 2" transform="translate(-166.184 -2.322)">
                                <g id="Groupe_1" data-name="Groupe 1" transform="translate(166.184 2.322)">
                                    <path
                                        id="path_1"
                                        data-name="Tracé 1"
                                        d="M205.011,79.976a38.827,38.827,0,1,1,38.827-38.827A38.871,38.871,0,0,1,205.011,79.976Zm0-72.623a33.8,33.8,0,1,0,33.8,33.8A33.834,33.834,0,0,0,205.011,7.353Z"
                                        transform="translate(-166.184 -2.322)"
                                        fill="#fff"
                                    ></path>
                                </g>
                                <path
                                    id="path_2"
                                    data-name="Tracé 2"
                                    d="M210.034,63.591a6.4,6.4,0,0,1,6.157-4.25c3.972,0,7.07,2.661,7.07,6.712V79.836c0,1.628.715,2.383,2.026,2.383.913,0,1.311-.556,1.668-1.669l5.481-16.96a6.348,6.348,0,0,1,6.157-4.25c3.972,0,7.07,2.661,7.07,6.712V86.787h-5.084V66.53c0-1.589-.715-2.383-2.066-2.383-.874,0-1.271.635-1.628,1.747l-5.561,16.921a6.159,6.159,0,0,1-6.116,4.29c-3.932,0-7.03-2.661-7.03-6.713V66.53c0-1.589-.715-2.383-2.065-2.383-.874,0-1.231.635-1.629,1.747l-7.308,20.893h-5.4Z"
                                    transform="translate(-20.019 -32.074)"
                                    fill="#fff"
                                ></path>
                            </g>
                        </svg>
                    </div>`,
        //
    }),
};
</script>
<style>
.main-container {
    background: linear-gradient(to right, var(--v-accent-base), var(--v-primary-base));
}
.gradient-app-bar {
    background: linear-gradient(to right, var(--v-accent-base), var(--v-primary-base));
}

.loader-fade-enter-active {
    transition: all 0.5s 0.3s cubic-bezier(0.11, 1.04, 0.8, 0.99);
}

.loader-fade-leave-active {
    transition: all 0.3s cubic-bezier(0.68, 0.04, 1, 0.7);
}
.loader-fade-enter-from {
    transform: scale(1.8);
    filter: blur(10px) !important;
    opacity: 0;
}
.loader-fade-leave-to {
    transform: scale(1.8);
    filter: blur(10px) !important;
    opacity: 0;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--v-secondary-base);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--v-primary-darken1);
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--v-primary-base);
}
.loader {
    z-index: 5;
    position: fixed;
    top: 0px;
    width: 100vw !important;
    height: 100vh;
    background: radial-gradient(#222222, #000000);
    display: flex;
    justify-content: center;
    align-items: center;
}
.loaderi {
    color: rgb(255, 255, 255);
    font-size: 1.5vw;
    font-family: roboto;
    font-weight: 300;
    position: absolute;
    filter: blur(0px);
}
@keyframes ldio-ossuqae55ee {
    0% {
        top: 163.66px;
        left: 163.66px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 45.089999999999996px;
        left: 45.089999999999996px;
        width: 237.14px;
        height: 237.14px;
        opacity: 0;
    }
}
.ldio-ossuqae55ee div {
    position: absolute;
    border-width: 3.34px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: ldio-ossuqae55ee 3.125s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ldio-ossuqae55ee div:nth-child(1) {
    border-color: #ffffff;
    animation-delay: 0s;
}
.ldio-ossuqae55ee div:nth-child(2) {
    border-color: #009eeb;
    animation-delay: -1.5625s;
}
.loadingio-spinner-ripple-64cwf99hv1r {
    width: 334px;
    height: 334px;
    display: inline-block;
    overflow: hidden;
    background: none;
}
.ldio-ossuqae55ee {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-ossuqae55ee div {
    box-sizing: content-box;
}
</style>
