<template>
    <div>
        <svg
            id="noiseFilter"
            width="1"
            height="1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <filter id="noise">
                <feTurbulence
                    type="fractalNoise"
                    baseFrequency="0.5"
                    seed="1"
                    numOctaves="5"
                    stitchTiles="stitch"
                    result="t">
                    <animate
                        attributeType="XML"
                        attributeName="seed"
                        from="1"
                        to="100"
                        dur="10s"
                        repeatCount="indefinite" />
                </feTurbulence>

                <feComponentTransfer
                    in="l"
                    result="out">
                    <feFuncA
                        type="linear"
                        slope="0.3"></feFuncA>
                    <feFuncR
                        type="discrete"
                        tableValues="0.15"></feFuncR>

                    <feFuncG
                        type="discrete"
                        tableValues="0.25"></feFuncG>

                    <feFuncB
                        type="discrete"
                        tableValues="1"></feFuncB>
                </feComponentTransfer>
                <feMerge>
                    <feMergeNode in="out"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
            </filter>
        </svg>
        <svg
            id="displacementFilter"
            width="1"
            height="1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <filter id="turbulence">
                <feTurbulence
                    id="feTurbulence"
                    type="turbulence"
                    :baseFrequency="filterParams.baseFrequency"
                    :numOctaves="filterParams.numOctaves"
                    result="turbulence" />
                <feDisplacementMap
                    id="feDisplacementMap"
                    in2="turbulence"
                    in="SourceGraphic"
                    result="displacementmap"
                    :scale="filterParams.scale"
                    xChannelSelector="R"
                    yChannelSelector="G" />
                <feGaussianBlur
                    in="SourceAlpha"
                    stdDeviation="0"
                    result="blur"></feGaussianBlur>
                <feOffset
                    in="displacementmap"
                    :dx="-1 * filterParams.dx"
                    :dy="filterParams.dy"
                    result="offsetblur"></feOffset>
                <feOffset
                    :dx="filterParams.dx"
                    :dy="-1 * filterParams.dy"
                    result="offsetblur2"
                    in="displacementmap"></feOffset>
                <feComponentTransfer
                    result="shadow1"
                    in="offsetblur">
                    <feFuncA
                        type="linear"
                        :slope="filterParams.alpha"></feFuncA>
                    <feFuncR
                        type="discrete"
                        tableValues="0"></feFuncR>

                    <feFuncG
                        type="discrete"
                        tableValues="1"></feFuncG>

                    <feFuncB
                        type="discrete"
                        tableValues="1"></feFuncB>
                </feComponentTransfer>
                <feComponentTransfer
                    opacity="0.2"
                    result="shadow2"
                    in="offsetblur2">
                    <feFuncA
                        type="linear"
                        :slope="filterParams.alpha"></feFuncA>

                    <feFuncR
                        type="discrete"
                        tableValues="1"></feFuncR>

                    <feFuncG
                        type="discrete"
                        tableValues="0"></feFuncG>

                    <feFuncB
                        type="discrete"
                        tableValues="1"></feFuncB>
                </feComponentTransfer>

                <feMerge>
                    <feMergeNode in="shadow1"></feMergeNode>
                    <feMergeNode in="shadow2"></feMergeNode>
                    <feMergeNode in="displacementmap"></feMergeNode>
                </feMerge>
            </filter>
        </svg>
        <div
            class="banner col-center filter"
            id="avatar">
            <v-avatar
                class="gradient-avatar pa-2"
                size="min(50vw, 50vh)">
                <transition
                    name="slide-fade"
                    enter-class="slide-fade-enter-from"
                    leave-to-class="slide-fade-leave-to">
                    <v-img
                        v-if="switch_avatar"
                        src="/img/avatar.jpg"
                        lazy-src="/img/avatar-lazy.jpg"
                        alt="avatar image"
                        height="calc(98%)"
                        ><template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                    indeterminate
                                    color="secondary"></v-progress-circular>
                            </v-row> </template
                    ></v-img>
                </transition>
                <transition
                    name="slide-fade"
                    enter-class="slide-fade-enter-from"
                    leave-to-class="slide-fade-leave-to">
                    <div
                        :class="{ 'text-h3': $vuetify.breakpoint.mdAndUp }"
                        v-if="!switch_avatar"
                        class="avatar-name secondary--text font-weight-bold">
                        Ruddy Riina
                    </div>
                </transition>
            </v-avatar>
            <div class="text-h3 secondary--text text-center font-weight-bold px-3 my-5">Imagine and Realize</div>
            <div class="text-h5 secondary--text px-3">{ {{ info_prompt }}<span class="cursor">_</span>}</div>
        </div>
        <div class="resume col-center">
            <v-row class="row-container px-4">
                <v-col
                    cols="2"
                    md="4"
                    class="col-center">
                    <v-timeline
                        dense
                        id="timeline">
                        <v-timeline-item
                            id="timeline-cursor"
                            class="timeline-title ease-animation"
                            :color="timeline_items[active_timeline].color">
                            <template v-slot:icon>
                                <v-btn
                                    small
                                    fab
                                    class="ease-animation"
                                    :color="timeline_items[active_timeline].color">
                                    <v-icon color="transparent">{{ timeline_items[active_timeline].icon }}</v-icon>
                                </v-btn>
                            </template>
                            <v-card
                                v-if="$vuetify.breakpoint.mdAndUp"
                                class="ease-animation"
                                style="cursor: pointer"
                                dark
                                :color="timeline_items[active_timeline].color"
                                elevation="0"
                                rounded="xl">
                                <v-card
                                    elevation="0"
                                    class="ease-animation"
                                    color="transparent"
                                    style="overflow: hidden">
                                    <v-card-title class="text-h6 font-weight-light transparent--text">{{
                                        timeline_items[active_timeline].title
                                    }}</v-card-title>
                                </v-card>
                            </v-card></v-timeline-item
                        >
                        <v-timeline-item
                            v-for="(item, i) in timeline_items"
                            :key="i"
                            :color="active_timeline == i ? item.color : 'secondary'"
                            :id="`timeline-${i}`"
                            fill-dot>
                            <template v-slot:icon>
                                <v-btn
                                    @click="viewStack(i)"
                                    small
                                    fab
                                    :color="active_timeline == i ? item.color : 'transparent'">
                                    <v-icon :color="active_timeline == i ? 'secondary' : 'white'">{{
                                        item.icon
                                    }}</v-icon>
                                </v-btn>
                            </template>
                            <v-card
                                v-if="$vuetify.breakpoint.mdAndUp"
                                dark
                                color="transparent"
                                elevation="0"
                                rounded="xl"
                                max-width="fit-content"
                                style="overflow: hidden">
                                <v-card
                                    elevation="0"
                                    color="transparent"
                                    @click="viewStack(i)"
                                    style="overflow: hidden">
                                    <v-card-title
                                        class="text-h6"
                                        :class="active_timeline == i ? 'secondary--text' : 'white--text'">
                                        {{ item.title }}
                                    </v-card-title>
                                </v-card>
                            </v-card></v-timeline-item
                        >
                    </v-timeline>
                </v-col>
                <v-col
                    cols="10"
                    md="8"
                    class="col-center pa-0 ma-0"
                    style="position: relative; overflow-x: visible">
                    <transition
                        name="stack-fade"
                        enter-class="stack-fade-enter-from"
                        leave-to-class="stack-fade-leave-to">
                        <div
                            v-if="this.active_timeline == 0"
                            class="font-weight-bold">
                            <v-card
                                class="mx-auto my-5 inset-shadow"
                                color="primary"
                                dark
                                max-width="1000">
                                <v-card-title>
                                    <v-icon
                                        :large="$vuetify.breakpoint.mdAndUp"
                                        left>
                                        mdi-stethoscope
                                    </v-icon>
                                    <span
                                        :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'text-body-2'"
                                        class="font-weight-light"
                                        >Physician</span
                                    >
                                </v-card-title>

                                <div class="px-4 text-body-1 font-weight-light">
                                    Grade : Doctorate <br />
                                    Period : 2009 - 2017<br />
                                </div>
                                <v-card-text>
                                    <span
                                        :class="{ 'text-h5': $vuetify.breakpoint.mdAndUp }"
                                        class="mt-4 font-weight-bold"
                                        >"A very long journey, full of turns and twists but giving a lot of discipline
                                        and wisdom. It shaped who I am."
                                    </span>
                                </v-card-text>
                            </v-card>
                            <v-card
                                class="mx-auto my-5 inset-shadow"
                                color="#3289D6"
                                dark
                                max-width="1000">
                                <v-card-title>
                                    <v-icon
                                        :large="$vuetify.breakpoint.mdAndUp"
                                        left>
                                        mdi-code-braces
                                    </v-icon>
                                    <span
                                        :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'text-body-2'"
                                        class="font-weight-light"
                                        >Software Engineering</span
                                    >
                                </v-card-title>

                                <div class="px-4 text-body-1 font-weight-light">
                                    Grade : Master II <br />
                                    Period : 2009 - 2015<br />
                                </div>
                                <v-card-text>
                                    <span
                                        :class="{ 'text-h5': $vuetify.breakpoint.mdAndUp }"
                                        class="mt-4 font-weight-bold"
                                        >"A passion? I loved the world of computers and technology."
                                    </span>
                                </v-card-text>
                            </v-card>
                            <v-card
                                class="mx-auto my-5 inset-shadow"
                                color="cyan darken-1"
                                dark
                                max-width="1000">
                                <v-card-title>
                                    <v-icon
                                        :large="$vuetify.breakpoint.mdAndUp"
                                        left>
                                        mdi-code-braces
                                    </v-icon>
                                    <span
                                        :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'text-body-2'"
                                        class="font-weight-light"
                                        >Certifications</span
                                    >
                                </v-card-title>

                                <div class="px-4 text-body-1 font-weight-light">
                                    <a
                                        class="white--text text-decoration-none"
                                        href="https://www.freecodecamp.org/certification/R_Ruddy/javascript-algorithms-and-data-structures"
                                        target="_blank">
                                        <v-icon left>mdi-link</v-icon>
                                        JavaScript Algorithms and Data Structures
                                    </a>
                                    <br />
                                    <a
                                        class="white--text text-decoration-none"
                                        href="https://www.freecodecamp.org/certification/R_Ruddy/machine-learning-with-python-v7"
                                        target="_blank">
                                        <v-icon left>mdi-link</v-icon>
                                        Machine Learning
                                    </a>
                                </div>
                                <v-card-text>
                                    <span
                                        :class="{ 'text-h5': $vuetify.breakpoint.mdAndUp }"
                                        class="mt-4 font-weight-bold"
                                        >"I was stuck in the flow. I couldn't stop. The more I learned, the more I
                                        wanted."
                                    </span>
                                </v-card-text>
                            </v-card>
                        </div>
                    </transition>
                    <transition
                        name="stack-fade"
                        enter-class="stack-fade-enter-from"
                        leave-to-class="stack-fade-leave-to">
                        <div
                            v-if="this.active_timeline == 1"
                            style="width: 100%">
                            <v-card
                                class="mx-auto my-5 inset-shadow"
                                color="indigo"
                                dark
                                width="100%"
                                max-width="1000">
                                <v-card-title>
                                    <v-icon
                                        :large="$vuetify.breakpoint.mdAndUp"
                                        left>
                                        mdi-code-json
                                    </v-icon>
                                    <span
                                        :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'text-body-2'"
                                        class="font-weight-light"
                                        >Skills : {{ stacks_items[active_stack].name }}</span
                                    >
                                </v-card-title>

                                <v-row class="pa-4">
                                    <v-col
                                        cols="12"
                                        sm="4"
                                        class="font-weight-light px-5"
                                        style="min-height: 560px; position: relative">
                                        <div
                                            class="my-5"
                                            v-for="(el, i) in stacks_items[active_stack].values"
                                            :key="i">
                                            {{ el.label }}
                                            <v-progress-linear
                                                color="white"
                                                :buffer-value="el.value"
                                                stream
                                                rounded
                                                height="8"></v-progress-linear>
                                        </div>
                                        <v-row style="position: absolute; bottom: 0px; width: 100%">
                                            <v-icon
                                                class="mx-1"
                                                :color="active_stack == i - 1 ? 'yellow' : 'secondary'"
                                                v-for="i in stacks_items.length"
                                                :key="i"
                                                @click="
                                                    () => {
                                                        active_stack = i - 1;
                                                        cycle_stack = false;
                                                    }
                                                "
                                                >mdi-minus</v-icon
                                            >
                                        </v-row>
                                    </v-col>
                                    <v-col
                                        v-if="$vuetify.breakpoint.smAndUp"
                                        cols="12"
                                        sm="8">
                                        <v-card
                                            height="550"
                                            color="secondary"
                                            elevation="0"
                                            style="white-space: pre; overflow: auto">
                                            <div
                                                class="px-5 codeScreen"
                                                v-html="code_prompt"></div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </div>
                    </transition>
                    <transition
                        name="stack-fade"
                        enter-class="stack-fade-enter-from"
                        leave-to-class="stack-fade-leave-to">
                        <div
                            v-if="this.active_timeline == 2"
                            class="font-weight-bold"
                            style="width: 100%">
                            <v-card
                                class="mx-auto my-5 inset-shadow"
                                color="#3289D6"
                                dark
                                max-width="1000">
                                <v-card-title>
                                    <v-icon
                                        :large="$vuetify.breakpoint.mdAndUp"
                                        left>
                                        mdi-code-braces
                                    </v-icon>
                                    <span
                                        :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'text-body-2'"
                                        class="font-weight-light"
                                        >Great Ponton, Ireland
                                    </span>
                                </v-card-title>

                                <div class="px-4 text-body-1 font-weight-light">
                                    Role : Lead developer <br />
                                    Period : 2023 - Today <br />
                                </div>
                                <v-card-text>
                                    <span
                                        :class="{ 'text-h5': $vuetify.breakpoint.mdAndUp }"
                                        class="mt-4 font-weight-bold"
                                        >"Freelancing has been a success for me, but working with the Great Ponton team
                                        has given me a lot of knowledge and wisdom."
                                    </span>
                                </v-card-text>
                            </v-card>
                            <v-card
                                class="mx-auto my-5 inset-shadow"
                                color="cyan darken-1"
                                dark
                                max-width="1000">
                                <v-card-title>
                                    <v-icon
                                        :large="$vuetify.breakpoint.mdAndUp"
                                        left>
                                        mdi-account-tie
                                    </v-icon>
                                    <span
                                        :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'text-body-2'"
                                        class="font-weight-light"
                                        >Meridius</span
                                    >
                                </v-card-title>

                                <div class="px-4 text-body-1 font-weight-light">
                                    Role : CEO <br />
                                    Period : Oct 2021 - Today <br />
                                </div>
                                <v-card-text>
                                    <span
                                        :class="{ 'text-h5': $vuetify.breakpoint.mdAndUp }"
                                        class="mt-4 font-weight-bold"
                                        >"Turns out the demand on the IT world keeps increasing, it's time to move into
                                        the next gear. I turned my passion into a career."
                                    </span>
                                </v-card-text>
                            </v-card>
                            <v-card
                                class="mx-auto my-5 inset-shadow"
                                color="teal"
                                dark
                                max-width="1000">
                                <v-card-title>
                                    <v-icon
                                        :large="$vuetify.breakpoint.mdAndUp"
                                        left>
                                        mdi-code-braces
                                    </v-icon>
                                    <span
                                        :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'text-body-2'"
                                        class="font-weight-light"
                                        >Freelancer</span
                                    >
                                </v-card-title>

                                <div class="px-4 text-body-1 font-weight-light">
                                    Role : AI Engineering - Full-stack developer <br />
                                    Period : 2015 - 2021 <br />
                                </div>
                                <v-card-text>
                                    <span
                                        :class="{ 'text-h5': $vuetify.breakpoint.mdAndUp }"
                                        class="mt-4 font-weight-bold"
                                        >"I started my IT journey on AI development as a freelancer. I had no choice, I
                                        had studies to finish. It was hard but I persevered."
                                    </span>
                                </v-card-text>
                            </v-card>
                        </div>
                    </transition>
                    <transition
                        name="stack-fade"
                        enter-class="stack-fade-enter-from"
                        leave-to-class="stack-fade-leave-to">
                        <div
                            v-if="this.active_timeline == 3"
                            style="width: 100%">
                            <v-card
                                class="mx-auto my-5 inset-shadow"
                                color="cyan"
                                dark
                                width="100%"
                                max-width="1000"
                                min-height="550">
                                <v-card-title>
                                    <v-icon
                                        :large="$vuetify.breakpoint.mdAndUp"
                                        left>
                                        mdi-lightbulb-on-outline
                                    </v-icon>
                                    <span
                                        :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'text-body-2'"
                                        class="font-weight-light"
                                        >Miscellaneous</span
                                    >
                                </v-card-title>

                                <v-row
                                    class="pa-4"
                                    style="justify-content: space-around">
                                    <div
                                        v-for="(el, i) in misc_items"
                                        :key="i"
                                        class="py-3 col-center"
                                        style="min-width: 110px">
                                        <div
                                            class="col-center"
                                            :style="
                                                $vuetify.breakpoint.mdAndUp
                                                    ? 'min-width: 150px; max-width: 185px; height: 128px'
                                                    : 'min-width: 64px; max-width: 95px; height: 64px'
                                            ">
                                            <img
                                                style="max-width: 100%; max-height: 90px"
                                                :src="el.logo"
                                                :alt="el.name" />
                                        </div>
                                        <span class="secondary--text text-caption">{{ el.name }}</span>
                                    </div>
                                </v-row>
                            </v-card>
                        </div>
                    </transition>
                    <transition
                        name="stack-fade"
                        enter-class="stack-fade-enter-from"
                        leave-to-class="stack-fade-leave-to">
                        <div
                            v-if="this.active_timeline == 4"
                            style="width: 100%">
                            <v-card
                                class="mx-auto my-5 inset-shadow"
                                color="teal"
                                dark
                                width="100%"
                                max-width="1000"
                                min-height="550">
                                <v-card-title>
                                    <v-icon
                                        :large="$vuetify.breakpoint.mdAndUp"
                                        left>
                                        mdi-check
                                    </v-icon>
                                    <span
                                        :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'text-body-2'"
                                        class="font-weight-light"
                                        >Other</span
                                    >
                                </v-card-title>

                                <v-row class="pa-4 ma-0">
                                    <v-col
                                        cols="4"
                                        class="px-2"
                                        v-for="(item, i) in other_items"
                                        :key="i">
                                        <div>{{ item.name }}</div>
                                        <div
                                            class="my-5"
                                            :class="$vuetify.breakpoint.mdAndUp ? 'text-body-1' : 'text-caption'"
                                            v-for="(el, n) in item.values"
                                            :key="n">
                                            {{ el.label }}
                                            <v-progress-linear
                                                color="white"
                                                :buffer-value="el.value"
                                                stream
                                                rounded
                                                height="8"></v-progress-linear>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </div>
                    </transition>
                </v-col>
            </v-row>
        </div>
        <div
            class="realisations col-center pl-10 pr-3"
            style="position: relative">
            <div
                v-for="(el, i) in realisations"
                :key="i"
                class="col-center"
                style="width: 100%; height: 100%; position: relative">
                <transition
                    name="stack-fade"
                    enter-class="stack-fade-enter-from"
                    leave-to-class="stack-fade-leave-to">
                    <v-row
                        v-if="active_realissation == i"
                        class="ma-0 pa-0"
                        style="width: 100%; max-width: 1200px; justify-content: space-between">
                        <v-col
                            style="backdrop-filter: blur(2px)"
                            cols="12"
                            md="4"
                            class="col-center secondary--text">
                            <div
                                class="col-center"
                                style="width: 350px; max-width: 75vw; height: 200px">
                                <v-img
                                    max-height="200"
                                    :src="el.logo"
                                    contain>
                                </v-img>
                            </div>
                            <div class="text-h3 my-5 text-center">{{ el.title }}</div>
                            <div class="text-h6 font-weight-light text-center">Role : {{ el.role }}</div>
                            <div
                                v-if="el.stack != ''"
                                class="font-weight-bold text-body-2 my-5 text-center">
                                Stacks : {{ el.stack }}
                            </div>
                            <div class="text-justify">
                                {{ el.description }}
                            </div>
                            <a
                                :href="el.url"
                                class="secondary--text"
                                target="_blank"
                                >{{ el.url }}</a
                            >
                        </v-col>
                        <v-col
                            cols="12"
                            class="col-center"
                            md="7">
                            <v-img
                                contain
                                width="100%"
                                :src="el.img_src"
                                :lazy-src="el.img_lazy_src"
                                ><template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                            indeterminate
                                            color="secondary"></v-progress-circular>
                                    </v-row> </template
                            ></v-img>
                        </v-col>
                    </v-row>
                </transition>
            </div>
            <div
                style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    position: absolute;
                    left: 10px;
                    top: 50%;
                ">
                <v-icon
                    class="ma-1"
                    small
                    :color="active_realissation == i - 1 ? 'yellow' : 'secondary'"
                    v-for="i in realisations.length"
                    :key="i"
                    @click="
                        () => {
                            active_realissation = i - 1;
                            cycle_realisation = false;
                        }
                    "
                    >{{ active_realissation == i - 1 ? 'mdi-circle' : 'mdi-circle-outline' }}</v-icon
                >
            </div>
        </div>
        <Footer id="footer" />
    </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import { nextTick } from 'vue';
const code1 = `
    sess = tf.compat.v1.InteractiveSession()
    frozen_graph = "./tensors.pb"
    with tf.compat.v1.gfile.GFile(frozen_graph, "rb") as f:
        graph_def = tf.compat.v1.GraphDef()
        graph_def.ParseFromString(f.read())
    
    sess.graph.as_default()
    tf.import_graph_def(graph_def)
    input_tensor = sess.graph.get_tensor_by_name("x:0") 
    output_tensor = sess.graph.get_tensor_by_name("Identity:0")    
    infrencing = sess.run(output_tensor, {"x:0": image})
    `;
const code2 = `
for (var p of this.$props.data) {
    let x = layout.h2s(p[0]) - off;
    let y = layout.p2s(p[1]) - 0.5;
    let changed = false;

    if (ctx.strokeStyle !== p[2]) {
        ctx.stroke();
        ctx.strokeStyle = p[2];
        changed = true;
    }

    if (changed) ctx.beginPath();
    ctx.moveTo(x, base);
    ctx.lineTo(x, y);
}
`;
const code3 = `
return new class extends Migration
{
    public function up()
    {
        Schema::create('files', function (Blueprint $table) {
            $table->id();
            $table->timestamps();
            $table->string('url');
            $table->string('filename');
        });
    }
    public function down()
    {
        Schema::dropIfExists('files');
    }
};
`;
const code4 = `
class SessionController extends Controller
{
    public function register(Request $request)
    {
        $validator = Validator::make($request->all(), [
            'email'         => 'required|string|email|max:255|unique:users',
            'password' => ['required', 'confirmed', Rules\\Password::defaults()],
        ]);

        if ($validator->fails()) {
             $response = [
                'success'  => false,
                'data'         => 'Validation Error',
                'message' => 'Validation Error',
            ];
            return response()->json($response, 422);
        }
`;

export default {
    name: 'Home',
    watch: {
        active_timeline(v) {
            this.do_code_prompt = v == 1;
            this.codePrompt(true, 0, 0);
            this.cycleStacks();
            const tl = document.getElementById(`timeline`);
            const el = document.getElementById(`timeline-${v}`);
            const top = tl.getBoundingClientRect().top - el.getBoundingClientRect().top;
            document.body.style.setProperty('--timeline-cursor-pos', `${top * -1}px`);
        },
    },
    beforeMount() {
        document.body.style.setProperty('--timeline-cursor-pos', `0px`);
    },
    mounted() {
        this.forceViewStack(0);
        this.doSwitch();
        this.infoPrompt(true, 0, 0);
        this.animCursor();
        this.cycleRealisation();
        window.addEventListener('resize', (e) => {
            setTimeout(() => {
                this.forceViewStack(this.active_timeline);
            }, 200);
        });
        this.$nextTick(function () {
            // Code that will run only after the
            // entire view has been rendered

            this.$emit('loaded');
        });
        this.animateFilter();
    },
    beforeDestroy() {
        window.removeEventListener('resize', (e) => {
            setTimeout(() => {
                this.forceViewStack(this.active_timeline);
            }, 200);
        });
    },
    methods: {
        animateFilter() {
            const wait = Math.random() * 7000;
            const duration = Math.random() * 150;
            const dx = Math.random() * 50;
            const dy = Math.random() * 15;
            this.filterParams.baseFrequency = 0.005;
            this.filterParams.numOctaves = 2;
            this.filterParams.scale = 30;
            this.filterParams.dx = dx;
            this.filterParams.dy = dy;
            this.filterParams.alpha = 0.6;
            setTimeout(() => {
                this.filterParams.baseFrequency = 0.3;
                this.filterParams.numOctaves = 2;
                this.filterParams.scale = 15;
                this.filterParams.dx = dx * 0.3;
                this.filterParams.dy = dy * 0.3;
                setTimeout(() => {
                    this.filterParams.baseFrequency = 0;
                    this.filterParams.numOctaves = 0;
                    this.filterParams.scale = 0;
                    this.filterParams.dx = 0;
                    this.filterParams.dy = 0;
                    this.filterParams.alpha = 0.0;
                    setTimeout(() => {
                        this.animateFilter();
                    }, wait);
                }, duration * 0.3);
            }, duration);
        },
        viewStack(id) {
            this.active_timeline = id;
        },
        forceViewStack(id) {
            this.active_timeline = id;
            const tl = document.getElementById(`timeline`);
            const el = document.getElementById(`timeline-${id}`);
            const top = tl.getBoundingClientRect().top - el.getBoundingClientRect().top;
            document.body.style.setProperty('--timeline-cursor-pos', `${top * -1}px`);
        },
        cycleStacks() {
            if (!this.cycle_stack) return;
            if (!this.do_code_prompt) {
                return;
            }
            const len = this.stacks_items.length;
            this.active_stack = this.active_stack + 1 < len ? this.active_stack + 1 : 0;
            setTimeout(() => {
                this.cycleStacks();
            }, 5000);
        },
        cycleRealisation() {
            if (!this.cycle_realisation) return;
            const len = this.realisations.length;
            this.active_realissation = this.active_realissation + 1 < len ? this.active_realissation + 1 : 0;
            setTimeout(() => {
                this.cycleRealisation();
            }, 5000);
        },
        animCursor() {
            this.cursor = !this.cursor;
            document.body.style.setProperty('--cursor', `${this.cursor ? 1 : 0}`);
            setTimeout(() => {
                this.animCursor();
            }, 300);
        },
        doSwitch() {
            this.switch_avatar = !this.switch_avatar;
            setTimeout(() => {
                this.doSwitch();
            }, this.switch_timeout);
        },
        infoPrompt(fwd, id, pos) {
            let pause = false;
            const len = this.info_prompt_templates[id].length;
            if (fwd) {
                const revert = pos + 1 > len;
                pos = revert ? pos : pos + 1;
                fwd = revert ? !fwd : fwd;
                pause = revert ? true : false;
            } else {
                const revert = pos - 1 < 0;
                pos = revert ? pos : pos - 1;
                fwd = revert ? !fwd : fwd;
                if (revert) {
                    id = id + 1 < this.info_prompt_templates.length ? id + 1 : 0;
                }
            }
            const timeout = (fwd ? 100 / this.prompt_speed : 20) * (pause ? 100 : 1);
            setTimeout(() => {
                const i_prompt = this.info_prompt_templates[id];
                this.info_prompt = i_prompt.substring(0, pos);
                this.infoPrompt(fwd, id, pos);
            }, timeout);
        },
        codePrompt(fwd, id, pos) {
            if (!this.do_code_prompt) {
                return;
            }
            let pause = false;
            const len = this.code_prompt_templates[id].length;

            if (fwd) {
                const revert = pos + 1 > len;
                pos = revert ? pos : pos + 1;
                fwd = revert ? !fwd : fwd;
                pause = revert ? true : false;
            } else {
                const revert = pos - 1 < 0;
                pos = revert ? pos : pos - 1;
                fwd = revert ? !fwd : fwd;
                if (revert) {
                    id = id + 1 < this.code_prompt_templates.length ? id + 1 : 0;
                }
            }
            const timeout = (fwd ? 100 / this.prompt_speed : 1) * (pause ? 1000 : 1) * (Math.random() + 0.01);

            setTimeout(() => {
                const i_prompt = this.code_prompt_templates[id];

                this.code_prompt = this.formatPrompt(i_prompt.substring(0, pos));
                this.codePrompt(fwd, id, pos);
            }, timeout);
        },
        formatPrompt(p) {
            p = p.replaceAll('\n', '<br />');
            p = p.replaceAll('Request', '<span class="cyan--text font-weight-black">Request</span>');
            p = p.replaceAll('sess', '<span class="cyan--text font-weight-black">sess</span>');
            p = p.replaceAll('tf', '<span class="cyan--text font-weight-black">tf</span>');
            p = p.replaceAll('Blueprint', '<span class="cyan--text font-weight-black">Blueprint</span>');
            p = p.replaceAll('Validator', '<span class="cyan--text font-weight-black">Validator</span>');
            p = p.replaceAll('Schema', '<span class="cyan--text font-weight-black">Schema</span>');
            p = p.replaceAll('function', '<span class="blue--text font-weight-black">function</span>');
            p = p.replaceAll('return', '<span class="blue--text font-weight-black">return</span>');
            p = p.replaceAll('v1', '<span class="cyan--text">v1</span>');
            p = p.replaceAll('GFile', '<span class="blue--text">GFile</span>');
            p = p.replaceAll('gfile', '<span class="blue--text">gfile</span>');
            p = p.replaceAll('GraphDef', '<span class="blue--text">GraphDef</span>');
            p = p.replaceAll('read', '<span class="blue--text">read</span>');
            p = p.replaceAll('get_tensor_by_name', '<span class="blue--text">get_tensor_by_name</span>');
            p = p.replaceAll('.graph', '<span class="blue--text">.graph</span>');
            p = p.replaceAll('as_default', '<span class="blue--text">as_default</span>');
            p = p.replaceAll('compat', '<span class="blue--text">compat</span>');
            p = p.replaceAll('InteractiveSession', '<span class="blue--text">InteractiveSession</span>');
            p = p.replaceAll('ParseFromString', '<span class="blue--text">ParseFromString</span>');
            p = p.replaceAll('import_graph_def', '<span class="blue--text">import_graph_def</span>');
            p = p.replaceAll('make', '<span class="blue--text font-weight-black">make</span>');
            p = p.replaceAll('create', '<span class="blue--text font-weight-black">create</span>');
            p = p.replaceAll('dropIfExists', '<span class="blue--text font-weight-black">dropIfExists</span>');
            p = p.replaceAll('json', '<span class="blue--text font-weight-black">json</span>');
            p = p.replaceAll('class ', '<span class="blue--text font-weight-black">class </span>');
            p = p.replaceAll('public', '<span class="cyan--text font-weight-black">public</span>');
            p = p.replaceAll('extends', '<span class="cyan--text font-weight-black">extends</span>');
            p = p.replaceAll('this', '<span class="blue--text font-weight-black">this</span>');
            p = p.replaceAll('if', '<span class="blue--text font-weight-black">if</span>');
            p = p.replaceAll('for', '<span class="blue--text font-weight-black">for</span>');
            p = p.replaceAll('let', '<span class="blue--text font-weight-black">let</span>');
            p = p.replaceAll('new', '<span class="cyan--text font-weight-black">new</span>');
            p = p.replaceAll('var', '<span class="blue--text font-weight-black">var</span>');
            p = p.replaceAll('const', '<span class="blue--text font-weight-black">const</span>');
            p = p.replaceAll('of ', '<span class="cyan--text font-weight-black">of </span>');
            p = p.replaceAll('layout', '<span class="cyan--text">layout</span>');
            p = p.replaceAll('ctx', '<span class="cyan--text">ctx</span>');
            p = p.replaceAll('"rb"', '<span class="orange--text  text--darken-3">"rb"</span>');
            p = p.replaceAll('"./tensors.pb"', '<span class="orange--text text--darken-3" >"./tensors.pb"</span>');
            p = p.replaceAll('"x:0"', '<span class="orange--text text--darken-3">"x:0"</span>');
            p = p.replaceAll('"Identity:0"', '<span class="orange--text text--darken-3">"Identity:0"</span>');
            p = p.replaceAll('$', '<span class="orange--text text--darken-3 font-weight-bold">$</span>');
            p = p.replaceAll('|', '<span class="orange--text text--darken-3 font-weight-bold">|</span>');
            p = p.replaceAll(`'`, `<span class="orange--text text--darken-3 font-weight-bold">'</span>`);
            p = p.replaceAll(' < ', '<span class="orange--text text--darken-3 font-weight-bold"> < </span>');
            p = p.replaceAll(' > ', '<span class="orange--text text--darken-3 font-weight-bold"> > </span>');
            p = p.replaceAll(' + ', '<span class="orange--text text--darken-3 font-weight-bold"> + </span>');
            p = p.replaceAll('.', '<span class="orange--text text--darken-3 font-weight-bold">.</span>');
            p = p.replaceAll(',', '<span class="orange--text text--darken-3 font-weight-bold">,</span>');
            p = p.replaceAll(';', '<span class="orange--text text--darken-3 font-weight-bold">;</span>');
            p = p.replaceAll(' ? ', '<span class="orange--text text--darken-3 font-weight-bold"> ? </span>');
            p = p.replaceAll(':', '<span class="orange--text text--darken-3 font-weight-bold">:</span>');
            p = p.replaceAll(' - ', '<span class="orange--text text--darken-3 font-weight-bold"> - </span>');
            p = p.replaceAll('->', '<span class="orange--text text--darken-3 font-weight-bold">-></span></span>');
            p = p.replaceAll('=>', '<span class="orange--text text--darken-3 font-weight-bold">=></span></span>');
            p = p.replaceAll('==', '<span class="orange--text text--darken-3 font-weight-bold">==</span>');
            p = p.replaceAll('!', '<span class="orange--text text--darken-3 font-weight-bold">!</span>');
            p = p.replaceAll(' = ', '<span class="orange--text text--darken-3 font-weight-bold"> = </span>');
            p = p.replaceAll('true', '<span class="red--text font-weight-bold">true</span>');
            p = p.replaceAll('false', '<span class="red--text font-weight-bold">false</span>');
            p = p.replaceAll('[', '<span class="yellow--text">[</span>');
            p = p.replaceAll(']', '<span class="yellow--text">]</span>');
            p = p.replaceAll('(', '<span class="yellow--text">(</span>');
            p = p.replaceAll(')', '<span class="yellow--text">)</span>');
            p = p.replaceAll('{', '<span class="yellow--text">{</span>');
            p = p.replaceAll('}', '<span class="yellow--text">}</span>');
            return p + `<span class="cursor">_</span>`;
        },
    },
    data() {
        return {
            filterParams: {
                baseFrequency: 0,
                numOctaves: 0,
                scale: 0,
                dx: 0,
                dy: 2,
                alpha: 0.0,
            },
            switch_avatar: false,
            switch_timeout: 5000,
            cursor: true,
            prompt_speed: 1,
            info_prompt: '',
            info_prompt_templates: [
                'Code',
                'Design',
                'UI/UX',
                'Artificial Intelligence',
                'Web App',
                'Mobile App',
                'Desktop App',
                'Website',
            ],
            do_code_prompt: false,
            code_prompt: '',
            code_prompt_templates: [code1, code3, code2, code4],
            //timelines
            active_timeline: 0,
            timeline_items: [
                {
                    color: 'primary',
                    icon: 'mdi-school',
                    title: 'Education',
                },
                {
                    color: 'indigo',
                    icon: 'mdi-code-braces',
                    title: 'Skills',
                },
                {
                    color: 'blue',
                    icon: 'mdi-account-tie',
                    title: 'Experience',
                },
                {
                    color: 'cyan',
                    icon: 'mdi-lightbulb-on-outline',
                    title: 'Misc',
                },
                {
                    color: 'teal',
                    icon: 'mdi-check',
                    title: 'Language',
                },
            ],
            active_stack: 0,
            cycle_stack: true,
            stacks_items: [
                {
                    name: 'Languages',
                    values: [
                        {
                            label: 'Python',
                            value: '90',
                        },
                        {
                            label: 'JS',
                            value: '85',
                        },
                        {
                            label: 'TS',
                            value: '80',
                        },
                        {
                            label: 'PHP',
                            value: '75',
                        },
                        {
                            label: 'CSS',
                            value: '65',
                        },
                        {
                            label: 'C++',
                            value: '60',
                        },
                        {
                            label: 'MT5',
                            value: '60',
                        },
                        {
                            label: 'Rust',
                            value: '60',
                        },
                        {
                            label: 'Java',
                            value: '40',
                        },
                        {
                            label: 'C#',
                            value: '40',
                        },
                    ],
                },
                {
                    name: 'Frameworks',
                    values: [
                        {
                            label: 'VueJS',
                            value: '95',
                        },
                        {
                            label: 'ReactJS',
                            value: '90',
                        },
                        {
                            label: 'Electron',
                            value: '90',
                        },
                        {
                            label: 'Laravel',
                            value: '75',
                        },
                        {
                            label: 'ExpressJS',
                            value: '70',
                        },
                        {
                            label: 'Flutter',
                            value: '70',
                        },
                        {
                            label: 'Django',
                            value: '70',
                        },
                        {
                            label: '.Net',
                            value: '40',
                        },
                    ],
                },
                {
                    name: 'Misc. Front-End',
                    values: [
                        {
                            label: 'Gsap',
                            value: '80',
                        },
                        {
                            label: 'WebGL',
                            value: '75',
                        },
                        {
                            label: 'SVG',
                            value: '75',
                        },
                        {
                            label: 'ThreeJS',
                            value: '40',
                        },
                    ],
                },
                {
                    name: 'ML/DL Modules',
                    values: [
                        {
                            label: 'Computer Vision',
                            value: '95',
                        },
                        {
                            label: 'TensorFlow',
                            value: '95',
                        },
                        {
                            label: 'Keras',
                            value: '95',
                        },
                        {
                            label: 'Torch',
                            value: '95',
                        },
                        {
                            label: 'Transformers',
                            value: '95',
                        },
                        {
                            label: 'Numpy',
                            value: '95',
                        },
                        {
                            label: 'Scikit-learn',
                            value: '85',
                        },
                        {
                            label: 'Pandas',
                            value: '80',
                        },
                        {
                            label: 'NLTK',
                            value: '75',
                        },
                    ],
                },
                {
                    name: 'Data Warehouse',
                    values: [
                        {
                            label: 'MySQL',
                            value: '95',
                        },
                        {
                            label: 'Firebase',
                            value: '80',
                        },
                        {
                            label: 'MongoDB',
                            value: '40',
                        },
                    ],
                },
            ],
            misc_items: [
                {
                    name: 'Adobe After Effects',
                    logo: '/img/adobe_after_effects_logo.svg',
                },
                {
                    name: 'Adobe Animate',
                    logo: '/img/adobe_animate_logo.svg',
                },
                {
                    name: 'Adobe Illustrator',
                    logo: '/img/adobe_illustrator_logo.svg',
                },
                {
                    name: 'Adobe Photoshop',
                    logo: '/img/adobe_photoshop_logo.svg',
                },
                {
                    name: 'Adobe Premiere',
                    logo: '/img/adobe_premiere_logo.svg',
                },
                {
                    name: 'Adobe XD',
                    logo: '/img/adobe_xd_logo.svg',
                },
                {
                    name: 'Unity',
                    logo: '/img/unity_technologies_logo.svg',
                },
                {
                    name: 'Steinberg Cubase',
                    logo: '/img/cubase_logo.svg',
                },
                {
                    name: 'Bandlab Cakewalk',
                    logo: '/img/bandlab_logo.svg',
                },
                {
                    name: 'Ableton',
                    logo: '/img/ableton_logo.svg',
                },
            ],
            other_items: [
                {
                    name: 'Malagasy',
                    values: [
                        {
                            label: 'Listening',
                            value: '95',
                        },
                        {
                            label: 'Speaking',
                            value: '95',
                        },
                        {
                            label: 'Reading',
                            value: '95',
                        },
                        {
                            label: 'Writing',
                            value: '80',
                        },
                    ],
                },
                {
                    name: 'Français',
                    values: [
                        {
                            label: 'Listening',
                            value: '95',
                        },
                        {
                            label: 'Speaking',
                            value: '90',
                        },
                        {
                            label: 'Reading',
                            value: '95',
                        },
                        {
                            label: 'Writing',
                            value: '95',
                        },
                    ],
                },
                {
                    name: 'English',
                    values: [
                        {
                            label: 'Listening',
                            value: '95',
                        },
                        {
                            label: 'Speaking',
                            value: '60',
                        },
                        {
                            label: 'Reading',
                            value: '95',
                        },
                        {
                            label: 'Writing',
                            value: '80',
                        },
                    ],
                },
            ],
            active_certification: 0,
            cycle_certification: true,
            realisations: [
                {
                    title: 'Meridius',
                    role: 'Full-stack developer',
                    stack: 'Gsap - Threejs',
                    description:
                        'Meridius is an application development company that can provide you with customized and feature-rich applications to help you boost your productivity.',
                    url: 'https://meridius.io',
                    img_src: '/img/meridius.jpg',
                    img_lazy_src: '/img/meridius-lazy.jpg',
                    logo: '/img/meridius-logo.png',
                },
                {
                    title: 'Qualitext',
                    role: 'Natural Language Processing developer - Web Scraping',
                    stack: 'Word2Vec - Doc2Vec - NLTK - Gensim - Numpy',
                    description:
                        'Qualitext is an online plagiarism detection software that allows you to control and guarantee the uniqueness of your writings (articles, web content, books, internship reports, end-of-study dissertations, etc.)',
                    url: 'https://qualitext.fr',
                    img_src: '/img/qualitext.jpg',
                    img_lazy_src: '/img/qualitext-lazy.jpg',
                    logo: '/img/qualitext-logo.png',
                },
                {
                    title: 'Trading platforms',
                    role: 'Project Manager - Full-stack developer - Maintainer',
                    stack: 'WebGL - Numpy - Numjs - Socket - VueJS - Laravel',
                    description:
                        'The project involves the development of personalized trading platform or market tools, which are equipped with several indicators and technical tools, some specific features such as "copy trading" and an all-in-one back office to manage customers. An overview of those software is available at:',
                    url: 'http://trading.meridius.io',
                    img_src: '/img/trading.jpg',
                    img_lazy_src: '/img/trading-lazy.jpg',
                    logo: '/img/trading-logo.png',
                },
                {
                    title: 'Cétasavoir',
                    role: 'Front-end developer',
                    stack: 'Laravel - VueJS',
                    description:
                        'Cétasavoir is an online training platform for responsible observation of marine megafauna. The software is implemented with a custom content creation tool, an user management interface and an automated digital certificate delivery system',
                    url: 'https://www.cetasavoir.org/',
                    img_src: '/img/cetasavoir.jpg',
                    img_lazy_src: '/img/cetasavoir-lazy.jpg',
                    logo: '/img/cetasavoir-logo.png',
                },
                {
                    title: 'Waveart',
                    role: 'Front-end developer',
                    stack: '',
                    description:
                        'Wave.art is part of a multi-cultural and international ecosystem of creatives and agencies specializing in digital, design, animation and new technologies.',
                    url: 'https://www.wave.art/',
                    img_src: '/img/waveart.jpg',
                    img_lazy_src: '/img/waveart-lazy.jpg',
                    logo: '/img/waveart-logo.png',
                },
                {
                    title: 'Gametraq',
                    role: 'AI developer',
                    stack: 'Open ComputerVision, Torch, Tensorflow, Numpy, Multithreaded Python',
                    description:
                        'Gamecam, a sport-focused company, delivers cutting-edge solutions for capturing and processing vital data in the dynamic realm of sports.',
                    url: 'https://www.gamecam.se/',
                    img_src: '/img/gametraq.jpg',
                    img_lazy_src: '/img/gametraq-lazy.jpg',
                    logo: '/img/gamecam-logo.png',
                },
                {
                    title: 'ResumeGPT',
                    role: 'Chrome Extension Lead Developer',
                    stack: 'VanillaJS, CSS, Indicative, Airtable, Chrome Extension Manifest V3',
                    description:
                        'RESUMEGPT is your comprehensive solution for job hunting and personal career development. "The ultimate career advancement tool"',
                    url: 'https://resume-example.com/builder',
                    img_src: '/img/resumegpt.jpg',
                    img_lazy_src: '/img/resumegpt-lazy.jpg',
                    logo: '/img/resumedone-logo.png',
                },
            ],
            active_realissation: 0,
            cycle_realisation: true,
        };
    },
    metaInfo() {
        return {
            title: 'Ruddy Riina - Full-stack developer from Madagascar',
            meta: [
                {
                    name: 'description',
                    content:
                        'I am able to set up a website with an extraordinary design, or an application containing specific functionalities or using the latest technology, to boost your activity.',
                },
                { property: 'og:title', content: 'Ruddy Riina - Full-stack developer from Madagascar' },
                { property: 'og:site_name', content: 'Ruddy Riina' },
                { property: 'og:type', content: 'website' },
                { name: 'robots', content: 'index,follow' },
            ],
        };
    },
    components: {
        Footer,
    },
};
</script>
<style>
#displacementFilter,
#noiseFilter {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
.filter {
    filter: url(#turbulence);
}
.noise {
    filter: url(#noise);
}
.col-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.row-container {
    width: 100%;
    max-width: 2000px;
    margin: 0px;
    padding: 0px;
}
.cursor {
    opacity: var(--cursor);
    user-select: none;
}
.banner {
    height: 100vh;
}
.resume {
    min-height: 100vh;
    background-color: var(--v-secondary-base);
    position: relative;
    z-index: 1;
}
.realisations {
    min-height: 100vh;
}
.ease-animation {
    transition: all 0.3s cubic-bezier(0.11, 1.04, 0.8, 0.99);
}
.timeline-title {
    top: var(--timeline-cursor-pos);
    position: absolute;
}
.gradient-avatar {
    border-width: min(3vh, 3vw);
    border-color: var(--v-secondary-base);
    border-style: solid;
    background: linear-gradient(to right, var(--v-accent-base) -100%, var(--v-primary-base) 200%);
}
.codeScreen {
    min-height: 100%;
    filter: url(#noise) url(#turbulence);
}
.avatar-name {
    position: absolute;
}
.inset-shadow {
    box-shadow: inset 3px 0px 7px 0px #00000050 !important;
}
.slide-fade-enter-active {
    transition: all 0.7s cubic-bezier(0.11, 1.04, 0.8, 0.99);
}

.slide-fade-leave-active {
    transition: all 0.5s cubic-bezier(0.68, 0.04, 1, 0.7);
}
.slide-fade-enter-from {
    transform: translateX(25vw) scale(0.8);
    filter: blur(10px);
    opacity: 0;
}
.slide-fade-leave-to {
    transform: translateX(-100vw) scale(0.97);
    opacity: 0;
}
.stack-fade-enter-active {
    transition: all 0.5s 0.3s cubic-bezier(0.11, 1.04, 0.8, 0.99);
    position: absolute;
}

.stack-fade-leave-active {
    transition: all 0.3s cubic-bezier(0.68, 0.04, 1, 0.7);
    position: absolute;
}
.stack-fade-enter-from {
    transform: translateX(10vw) scale(0.8);
    filter: blur(10px);
    opacity: 0;
}
.stack-fade-leave-to {
    transform: translateX(-10vw) scale(0.97);
    opacity: 0;
}
.str--text {
    color: yellow !important;
}
</style>
